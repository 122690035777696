module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleTagManager":{"trackingId":"G-2BFTV0991Z","anonymize":true},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Dreamland Web","short_name":"Dreamland","description":"Sumérgete en nuestro mundo: espacios de trabajo únicos y creativos para dar vida a tus proyectos. ¡Únete a nuestra comunidad!","start_url":"/","lang":"es","background_color":"#EAE6DC","theme_color":"#fff","display":"minimal-ui","icon":"src/svg/favicon.svg","localize":[{"start_url":"/en/","lang":"en","name":"Dreamland - Coworking & design room rental in downtown Valencia","short_name":"Dreamland","description":"Immerse yourself in our world: unique and creative workspaces to bring your projects to life. Join our community!"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b57992b9a34e8b5afb28d0eed73522f9"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"runtimeCaching":[{"urlPattern":{},"handler":"NetworkOnly"}]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["es","en"],"defaultLanguage":"es","siteUrl":"https://dreamlandcoworking.com","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
